.border-top {
  border-top: 1px solid #e5e5e5;
}
.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.icon {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}
