/*
 * Ray
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757c;
  --gray-dark: #343a40;
  --primary: #27b0cc;
  --secondary: #4513bb;
  --success: #27cc98;
  --info: #0095f1;
  --warning: #f0b954;
  --danger: #f25270;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Euclid Flex B", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Euclid Flex B", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #6c757c;
  text-align: left;
  background-color: #f8f9fa; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.3125rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 500; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #27b0cc;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #1b798c;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  color: #6c757c;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.625rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (max-width: 1200px) {
    legend {
      font-size: calc(1.275rem + 0.3vw) ; } }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .inspiration-post-card__title, .h6 {
  margin-bottom: 0.3125rem;
  font-weight: 500;
  line-height: 1.2;
  color: #212529; }

h1, .h1 {
  font-size: 2.5rem; }
  @media (max-width: 1200px) {
    h1, .h1 {
      font-size: calc(1.375rem + 1.5vw) ; } }

h2, .h2 {
  font-size: 2rem; }
  @media (max-width: 1200px) {
    h2, .h2 {
      font-size: calc(1.325rem + 0.9vw) ; } }

h3, .h3 {
  font-size: 1.75rem; }
  @media (max-width: 1200px) {
    h3, .h3 {
      font-size: calc(1.3rem + 0.6vw) ; } }

h4, .h4 {
  font-size: 1.5rem; }
  @media (max-width: 1200px) {
    h4, .h4 {
      font-size: calc(1.275rem + 0.3vw) ; } }

h5, .h5, .inspiration-post-card__title {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-1 {
      font-size: calc(1.725rem + 5.7vw) ; } }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-2 {
      font-size: calc(1.675rem + 5.1vw) ; } }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-3 {
      font-size: calc(1.575rem + 3.9vw) ; } }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-4 {
      font-size: calc(1.475rem + 2.7vw) ; } }

hr {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 0.625rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757c; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.form-control {
  display: block;
  width: 100%;
  height: calc(1em + 1.875rem + 2px);
  padding: 0.9375rem 1.25rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control.focus, .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #27b0cc;
    outline: 0;
    box-shadow: none; }
  .form-control::placeholder {
    color: #6c757c;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #212529;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.9375rem + 1px);
  padding-bottom: calc(0.9375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1; }

.col-form-label-lg {
  padding-top: calc(1.25rem + 1px);
  padding-bottom: calc(1.25rem + 1px);
  font-size: 1.25rem;
  line-height: 1; }

.col-form-label-sm {
  padding-top: calc(0.625rem + 1px);
  padding-bottom: calc(0.625rem + 1px);
  font-size: 0.875rem;
  line-height: 1; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  margin-bottom: 0;
  line-height: 1;
  color: #6c757c;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1em + 1.25rem + 2px);
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.3125rem; }

.form-control-lg {
  height: calc(1em + 2.5rem + 2px);
  padding: 1.25rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 0.3125rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757c; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #27cc98; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(39, 204, 152, 0.9);
  border-radius: 0.625rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #27cc98; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #27cc98;
    box-shadow: 0 0 0 0.2rem rgba(39, 204, 152, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #27cc98; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #27cc98;
    box-shadow: 0 0 0 0.2rem rgba(39, 204, 152, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #27cc98; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #27cc98; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #27cc98; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #4adcae;
  background-color: #4adcae; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(39, 204, 152, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #27cc98; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #27cc98; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #27cc98;
  box-shadow: 0 0 0 0.2rem rgba(39, 204, 152, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f25270; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(242, 82, 112, 0.9);
  border-radius: 0.625rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f25270; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #f25270;
    box-shadow: 0 0 0 0.2rem rgba(242, 82, 112, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #f25270; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #f25270;
    box-shadow: 0 0 0 0.2rem rgba(242, 82, 112, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f25270; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #f25270; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #f25270; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f68197;
  background-color: #f68197; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(242, 82, 112, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f25270; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #f25270; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #f25270;
  box-shadow: 0 0 0 0.2rem rgba(242, 82, 112, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 500;
  color: #6c757c;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.9375rem 1.5625rem;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0.3125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #6c757c;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(39, 176, 204, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  background-color: #27b0cc;
  color: #fff;
  border-color: #27b0cc; }
  .btn-primary:hover {
    background-color: #2194ac;
    color: #fff;
    border-color: #1f8ba1; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(71, 188, 212, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #27b0cc;
    border-color: #27b0cc; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1f8ba1;
    border-color: #1d8296; }

.btn-secondary {
  background-color: #4513bb;
  color: #fff;
  border-color: #4513bb; }
  .btn-secondary:hover {
    background-color: #380f98;
    color: #fff;
    border-color: #340e8d; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(97, 54, 197, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #4513bb;
    border-color: #4513bb; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #340e8d;
    border-color: #300d81; }

.btn-success {
  background-color: #27cc98;
  color: #fff;
  border-color: #27cc98; }
  .btn-success:hover {
    background-color: #21ac80;
    color: #fff;
    border-color: #1fa178; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(71, 212, 167, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #27cc98;
    border-color: #27cc98; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1fa178;
    border-color: #1d9670; }

.btn-info {
  background-color: #0095f1;
  color: #fff;
  border-color: #0095f1; }
  .btn-info:hover {
    background-color: #007dcb;
    color: #fff;
    border-color: #0075be; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 165, 243, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #0095f1;
    border-color: #0095f1; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #0075be;
    border-color: #006eb1; }

.btn-warning {
  background-color: #f0b954;
  color: #212529;
  border-color: #f0b954; }
  .btn-warning:hover {
    background-color: #edab31;
    color: #212529;
    border-color: #eca625; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(209, 163, 78, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #f0b954;
    border-color: #f0b954; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #eca625;
    border-color: #eba119; }

.btn-danger {
  background-color: #f25270;
  color: #fff;
  border-color: #f25270; }
  .btn-danger:hover {
    background-color: #ef2e53;
    color: #fff;
    border-color: #ee2349; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 108, 133, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #f25270;
    border-color: #f25270; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ee2349;
    border-color: #ee173f; }

.btn-light {
  background-color: #f8f9fa;
  color: #212529;
  border-color: #f8f9fa; }
  .btn-light:hover {
    background-color: #e2e6ea;
    color: #212529;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }

.btn-dark {
  background-color: #343a40;
  color: #fff;
  border-color: #343a40; }
  .btn-dark:hover {
    background-color: #23272b;
    color: #fff;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }

.btn-outline-primary {
  color: #27b0cc;
  background-color: #fff;
  background-image: none;
  border-color: #bce9f2; }
  .btn-outline-primary:hover {
    color: #27b0cc;
    background-color: #fff;
    border-color: #27b0cc; }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled, .btn-outline-primary.disabled:hover {
    color: #27b0cc;
    background-color: transparent;
    border-color: #bce9f2; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #27b0cc; }

.btn-outline-secondary {
  color: #4513bb;
  background-color: #fff;
  background-image: none;
  border-color: #c2acf7; }
  .btn-outline-secondary:hover {
    color: #4513bb;
    background-color: #fff;
    border-color: #4513bb; }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled, .btn-outline-secondary.disabled:hover {
    color: #4513bb;
    background-color: transparent;
    border-color: #c2acf7; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #4513bb; }

.btn-outline-success {
  color: #27cc98;
  background-color: #fff;
  background-image: none;
  border-color: #bcf2e1; }
  .btn-outline-success:hover {
    color: #27cc98;
    background-color: #fff;
    border-color: #27cc98; }
  .btn-outline-success.disabled, .btn-outline-success:disabled, .btn-outline-success.disabled:hover {
    color: #27cc98;
    background-color: transparent;
    border-color: #bcf2e1; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #27cc98; }

.btn-outline-info {
  color: #0095f1;
  background-color: #fff;
  background-image: none;
  border-color: #aee0ff; }
  .btn-outline-info:hover {
    color: #0095f1;
    background-color: #fff;
    border-color: #0095f1; }
  .btn-outline-info.disabled, .btn-outline-info:disabled, .btn-outline-info.disabled:hover {
    color: #0095f1;
    background-color: transparent;
    border-color: #aee0ff; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #0095f1; }

.btn-outline-warning {
  color: #f0b954;
  background-color: #fff;
  background-image: none;
  border-color: #fbeacc; }
  .btn-outline-warning:hover {
    color: #f0b954;
    background-color: #fff;
    border-color: #f0b954; }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled, .btn-outline-warning.disabled:hover {
    color: #f0b954;
    background-color: transparent;
    border-color: #fbeacc; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #f0b954; }

.btn-outline-danger {
  color: #f25270;
  background-color: #fff;
  background-image: none;
  border-color: #fbcbd4; }
  .btn-outline-danger:hover {
    color: #f25270;
    background-color: #fff;
    border-color: #f25270; }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled, .btn-outline-danger.disabled:hover {
    color: #f25270;
    background-color: transparent;
    border-color: #fbcbd4; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #f25270; }

.btn-outline-light {
  color: #f8f9fa;
  background-color: #fff;
  background-image: none;
  border-color: #fdfdfe; }
  .btn-outline-light:hover {
    color: #f8f9fa;
    background-color: #fff;
    border-color: #f8f9fa; }
  .btn-outline-light.disabled, .btn-outline-light:disabled, .btn-outline-light.disabled:hover {
    color: #f8f9fa;
    background-color: transparent;
    border-color: #fdfdfe; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #f8f9fa; }

.btn-outline-dark {
  color: #343a40;
  background-color: #fff;
  background-image: none;
  border-color: #bec4ca; }
  .btn-outline-dark:hover {
    color: #343a40;
    background-color: #fff;
    border-color: #343a40; }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled, .btn-outline-dark.disabled:hover {
    color: #343a40;
    background-color: transparent;
    border-color: #bec4ca; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #343a40; }

.btn-link {
  font-weight: 400;
  color: #27b0cc;
  text-decoration: none; }
  .btn-link:hover {
    color: #1b798c;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757c;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 1.25rem 1.875rem;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 0.3125rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.625rem 0.9375rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.3125rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #6c757c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.3125rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 1.25rem 1.25rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:first-child {
    border-top-left-radius: calc(0.3125rem - 1px);
    border-top-right-radius: calc(0.3125rem - 1px); }
  .dropdown-item:last-child {
    border-bottom-right-radius: calc(0.3125rem - 1px);
    border-bottom-left-radius: calc(0.3125rem - 1px); }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #212529;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757c;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0 1.25rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757c;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 1.25rem 1.25rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 1.17188rem;
  padding-left: 1.17188rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.70312rem;
  padding-left: 0.70312rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.40625rem;
  padding-left: 1.40625rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.9375rem 1.25rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #dee2e6;
  border-radius: 0.3125rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1em + 2.5rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 1.25rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 0.3125rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1em + 1.25rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.3125rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2.25rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.75rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #27cc98;
    background-color: #27cc98; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #f25270; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b5e7f1;
    border-color: #b5e7f1; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757c; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.125rem;
    left: -1.75rem;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    pointer-events: none;
    content: "";
    background-color: #f25270;
    border: #f25270 solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.125rem;
    left: -1.75rem;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.625rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #27b0cc;
  background-color: #27b0cc; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(39, 176, 204, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(39, 176, 204, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(39, 176, 204, 0.5); }

.custom-switch {
  padding-left: 3rem; }
  .custom-switch .custom-control-label::before {
    left: -3rem;
    width: 2.5rem;
    pointer-events: all;
    border-radius: 0.625rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.125rem + 2px);
    left: calc(-3rem + 2px);
    width: calc(1.25rem - 4px);
    height: calc(1.25rem - 4px);
    background-color: #f25270;
    border-radius: 0.625rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #f25270;
    transform: translateX(1.25rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(39, 176, 204, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1em + 1.875rem + 2px);
  padding: 0.9375rem 2.25rem 0.9375rem 1.25rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1;
  color: #212529;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.25rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.625rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #27b0cc;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(39, 176, 204, 0.25); }
    .custom-select:focus::-ms-value {
      color: #212529;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1.25rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757c;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1em + 1.25rem + 2px);
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 1.25rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1em + 2.5rem + 2px);
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1.25rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1em + 1.875rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1em + 1.875rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #27b0cc;
    box-shadow: none; }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1em + 1.875rem + 2px);
  padding: 0.9375rem 1.25rem;
  font-weight: 300;
  line-height: 1;
  color: #212529;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.3125rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1em + 1.875rem);
    padding: 0.9375rem 1.25rem;
    line-height: 1;
    color: #212529;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.3125rem 0.3125rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #f8f9fa, none; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #f8f9fa, none; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #f8f9fa, none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #27b0cc;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b5e7f1; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #27b0cc;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b5e7f1; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #27b0cc;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b5e7f1; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #ced4da;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #ced4da; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #ced4da; }
    .nav-tabs .nav-link.disabled {
      color: #ced4da;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #f8f9fa;
    border-color: #ced4da #ced4da #f8f9fa; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.625rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #27b0cc; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.card, .inspiration-post-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #fff;
  border-radius: 0.3125rem; }
  .card > hr, .inspiration-post-card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child, .inspiration-post-card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem; }
  .card > .list-group:last-child .list-group-item:last-child, .inspiration-post-card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0 solid #fff; }
  .card-header:first-child {
    border-radius: calc(0.3125rem - 0) calc(0.3125rem - 0) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 0 solid #fff; }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.3125rem - 0) calc(0.3125rem - 0); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.3125rem - 0); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.3125rem - 0);
  border-top-right-radius: calc(0.3125rem - 0); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.3125rem - 0);
  border-bottom-left-radius: calc(0.3125rem - 0); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card, .card-deck .inspiration-post-card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card, .card-deck .inspiration-post-card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card, .card-group > .inspiration-post-card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card, .card-group > .inspiration-post-card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card, .card-group > .inspiration-post-card + .card, .card-group > .card + .inspiration-post-card, .card-group > .inspiration-post-card + .inspiration-post-card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child), .card-group > .inspiration-post-card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top, .card-group > .inspiration-post-card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header,
          .card-group > .inspiration-post-card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .inspiration-post-card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer,
          .card-group > .inspiration-post-card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child), .card-group > .inspiration-post-card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top, .card-group > .inspiration-post-card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header,
          .card-group > .inspiration-post-card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .inspiration-post-card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer,
          .card-group > .inspiration-post-card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card, .card-columns .inspiration-post-card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card, .card-columns .inspiration-post-card {
      display: inline-block;
      width: 100%; } }

.accordion > .card, .accordion > .inspiration-post-card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child, .accordion > .inspiration-post-card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type), .accordion > .inspiration-post-card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type, .accordion > .inspiration-post-card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type, .accordion > .inspiration-post-card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header, .accordion > .inspiration-post-card .card-header {
    margin-bottom: 0; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.625rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #27b0cc;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #1b798c;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(39, 176, 204, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #27b0cc;
  border-color: #27b0cc; }

.page-item.disabled .page-link {
  color: #6c757c;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem; }

.badge {
  display: inline-block;
  padding: 0.4em 0.5em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.75em;
  padding-left: 0.75em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #27b0cc; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #1f8ba1; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(39, 176, 204, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #4513bb; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #340e8d; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(69, 19, 187, 0.5); }

.badge-success {
  color: #fff;
  background-color: #27cc98; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1fa178; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(39, 204, 152, 0.5); }

.badge-info {
  color: #fff;
  background-color: #0095f1; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #0075be; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 149, 241, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #f0b954; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #eca625; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(240, 185, 84, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #f25270; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #ee2349; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(242, 82, 112, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.625rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 500; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #145c6a;
  background-color: #d4eff5;
  border-color: #c3e9f1; }
  .alert-primary hr {
    border-top-color: #aee1ec; }
  .alert-primary .alert-link {
    color: #0c373f; }

.alert-secondary {
  color: #240a61;
  background-color: #dad0f1;
  border-color: #cbbdec; }
  .alert-secondary hr {
    border-top-color: #bba9e6; }
  .alert-secondary .alert-link {
    color: #130533; }

.alert-success {
  color: #146a4f;
  background-color: #d4f5ea;
  border-color: #c3f1e2; }
  .alert-success hr {
    border-top-color: #aeecd8; }
  .alert-success .alert-link {
    color: #0c3f2f; }

.alert-info {
  color: #004d7d;
  background-color: #cceafc;
  border-color: #b8e1fb; }
  .alert-info hr {
    border-top-color: #a0d7fa; }
  .alert-info .alert-link {
    color: #002e4a; }

.alert-warning {
  color: #7d602c;
  background-color: #fcf1dd;
  border-color: #fbebcf; }
  .alert-warning hr {
    border-top-color: #f9e1b7; }
  .alert-warning .alert-link {
    color: #57431f; }

.alert-danger {
  color: #7e2b3a;
  background-color: #fcdce2;
  border-color: #fbcfd7; }
  .alert-danger hr {
    border-top-color: #f9b7c3; }
  .alert-danger .alert-link {
    color: #581e28; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.625rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #27b0cc;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  @media (max-width: 1200px) {
    .close {
      font-size: calc(1.275rem + 0.3vw) ; } }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #27b0cc !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1f8ba1 !important; }

.bg-secondary {
  background-color: #4513bb !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #340e8d !important; }

.bg-success {
  background-color: #27cc98 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1fa178 !important; }

.bg-info {
  background-color: #0095f1 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0075be !important; }

.bg-warning {
  background-color: #f0b954 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #eca625 !important; }

.bg-danger {
  background-color: #f25270 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ee2349 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #27b0cc !important; }

.border-secondary {
  border-color: #4513bb !important; }

.border-success {
  border-color: #27cc98 !important; }

.border-info {
  border-color: #0095f1 !important; }

.border-warning {
  border-color: #f0b954 !important; }

.border-danger {
  border-color: #f25270 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.3125rem !important; }

.rounded {
  border-radius: 0.625rem !important; }

.rounded-top {
  border-top-left-radius: 0.625rem !important;
  border-top-right-radius: 0.625rem !important; }

.rounded-right {
  border-top-right-radius: 0.625rem !important;
  border-bottom-right-radius: 0.625rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.625rem !important;
  border-bottom-left-radius: 0.625rem !important; }

.rounded-left {
  border-top-left-radius: 0.625rem !important;
  border-bottom-left-radius: 0.625rem !important; }

.rounded-lg {
  border-radius: 1.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0 15px 0 rgba(73, 80, 87, 0.04) !important; }

.shadow {
  box-shadow: 0 0 30px 0 rgba(73, 80, 87, 0.04) !important; }

.shadow-lg {
  box-shadow: 0 0 30px 0 rgba(73, 80, 87, 0.12) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.625rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.625rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.625rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.625rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.625rem !important; }

.m-2 {
  margin: 1.25rem !important; }

.mt-2,
.my-2 {
  margin-top: 1.25rem !important; }

.mr-2,
.mx-2 {
  margin-right: 1.25rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 1.25rem !important; }

.ml-2,
.mx-2 {
  margin-left: 1.25rem !important; }

.m-3 {
  margin: 1.875rem !important; }

.mt-3,
.my-3 {
  margin-top: 1.875rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1.875rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1.875rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1.875rem !important; }

.m-4 {
  margin: 2.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 2.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 2.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 2.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 2.5rem !important; }

.m-5 {
  margin: 3.125rem !important; }

.mt-5,
.my-5 {
  margin-top: 3.125rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3.125rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3.125rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3.125rem !important; }

.m-half {
  margin: 0.3125rem !important; }

.mt-half,
.my-half {
  margin-top: 0.3125rem !important; }

.mr-half,
.mx-half {
  margin-right: 0.3125rem !important; }

.mb-half,
.my-half {
  margin-bottom: 0.3125rem !important; }

.ml-half,
.mx-half {
  margin-left: 0.3125rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.625rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.625rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.625rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.625rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.625rem !important; }

.p-2 {
  padding: 1.25rem !important; }

.pt-2,
.py-2 {
  padding-top: 1.25rem !important; }

.pr-2,
.px-2 {
  padding-right: 1.25rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 1.25rem !important; }

.pl-2,
.px-2 {
  padding-left: 1.25rem !important; }

.p-3 {
  padding: 1.875rem !important; }

.pt-3,
.py-3 {
  padding-top: 1.875rem !important; }

.pr-3,
.px-3 {
  padding-right: 1.875rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1.875rem !important; }

.pl-3,
.px-3 {
  padding-left: 1.875rem !important; }

.p-4 {
  padding: 2.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 2.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 2.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 2.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 2.5rem !important; }

.p-5 {
  padding: 3.125rem !important; }

.pt-5,
.py-5 {
  padding-top: 3.125rem !important; }

.pr-5,
.px-5 {
  padding-right: 3.125rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3.125rem !important; }

.pl-5,
.px-5 {
  padding-left: 3.125rem !important; }

.p-half {
  padding: 0.3125rem !important; }

.pt-half,
.py-half {
  padding-top: 0.3125rem !important; }

.pr-half,
.px-half {
  padding-right: 0.3125rem !important; }

.pb-half,
.py-half {
  padding-bottom: 0.3125rem !important; }

.pl-half,
.px-half {
  padding-left: 0.3125rem !important; }

.m-n1 {
  margin: -0.625rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.625rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.625rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.625rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.625rem !important; }

.m-n2 {
  margin: -1.25rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -1.25rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -1.25rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -1.25rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -1.25rem !important; }

.m-n3 {
  margin: -1.875rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1.875rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1.875rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1.875rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1.875rem !important; }

.m-n4 {
  margin: -2.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -2.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -2.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -2.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -2.5rem !important; }

.m-n5 {
  margin: -3.125rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3.125rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3.125rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3.125rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3.125rem !important; }

.m-nhalf {
  margin: -0.3125rem !important; }

.mt-nhalf,
.my-nhalf {
  margin-top: -0.3125rem !important; }

.mr-nhalf,
.mx-nhalf {
  margin-right: -0.3125rem !important; }

.mb-nhalf,
.my-nhalf {
  margin-bottom: -0.3125rem !important; }

.ml-nhalf,
.mx-nhalf {
  margin-left: -0.3125rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.625rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.625rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.625rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.625rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.625rem !important; }
  .m-sm-2 {
    margin: 1.25rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1.25rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1.25rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1.25rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1.25rem !important; }
  .m-sm-3 {
    margin: 1.875rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.875rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.875rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.875rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.875rem !important; }
  .m-sm-4 {
    margin: 2.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2.5rem !important; }
  .m-sm-5 {
    margin: 3.125rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3.125rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3.125rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3.125rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3.125rem !important; }
  .m-sm-half {
    margin: 0.3125rem !important; }
  .mt-sm-half,
  .my-sm-half {
    margin-top: 0.3125rem !important; }
  .mr-sm-half,
  .mx-sm-half {
    margin-right: 0.3125rem !important; }
  .mb-sm-half,
  .my-sm-half {
    margin-bottom: 0.3125rem !important; }
  .ml-sm-half,
  .mx-sm-half {
    margin-left: 0.3125rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.625rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.625rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.625rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.625rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.625rem !important; }
  .p-sm-2 {
    padding: 1.25rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1.25rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1.25rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1.25rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1.25rem !important; }
  .p-sm-3 {
    padding: 1.875rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.875rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.875rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.875rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.875rem !important; }
  .p-sm-4 {
    padding: 2.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2.5rem !important; }
  .p-sm-5 {
    padding: 3.125rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3.125rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3.125rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3.125rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3.125rem !important; }
  .p-sm-half {
    padding: 0.3125rem !important; }
  .pt-sm-half,
  .py-sm-half {
    padding-top: 0.3125rem !important; }
  .pr-sm-half,
  .px-sm-half {
    padding-right: 0.3125rem !important; }
  .pb-sm-half,
  .py-sm-half {
    padding-bottom: 0.3125rem !important; }
  .pl-sm-half,
  .px-sm-half {
    padding-left: 0.3125rem !important; }
  .m-sm-n1 {
    margin: -0.625rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.625rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.625rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.625rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.625rem !important; }
  .m-sm-n2 {
    margin: -1.25rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -1.25rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -1.25rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -1.25rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -1.25rem !important; }
  .m-sm-n3 {
    margin: -1.875rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1.875rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1.875rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1.875rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1.875rem !important; }
  .m-sm-n4 {
    margin: -2.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -2.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -2.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -2.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -2.5rem !important; }
  .m-sm-n5 {
    margin: -3.125rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3.125rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3.125rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3.125rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3.125rem !important; }
  .m-sm-nhalf {
    margin: -0.3125rem !important; }
  .mt-sm-nhalf,
  .my-sm-nhalf {
    margin-top: -0.3125rem !important; }
  .mr-sm-nhalf,
  .mx-sm-nhalf {
    margin-right: -0.3125rem !important; }
  .mb-sm-nhalf,
  .my-sm-nhalf {
    margin-bottom: -0.3125rem !important; }
  .ml-sm-nhalf,
  .mx-sm-nhalf {
    margin-left: -0.3125rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.625rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.625rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.625rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.625rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.625rem !important; }
  .m-md-2 {
    margin: 1.25rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1.25rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1.25rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1.25rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1.25rem !important; }
  .m-md-3 {
    margin: 1.875rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.875rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.875rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.875rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.875rem !important; }
  .m-md-4 {
    margin: 2.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2.5rem !important; }
  .m-md-5 {
    margin: 3.125rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3.125rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3.125rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3.125rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3.125rem !important; }
  .m-md-half {
    margin: 0.3125rem !important; }
  .mt-md-half,
  .my-md-half {
    margin-top: 0.3125rem !important; }
  .mr-md-half,
  .mx-md-half {
    margin-right: 0.3125rem !important; }
  .mb-md-half,
  .my-md-half {
    margin-bottom: 0.3125rem !important; }
  .ml-md-half,
  .mx-md-half {
    margin-left: 0.3125rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.625rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.625rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.625rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.625rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.625rem !important; }
  .p-md-2 {
    padding: 1.25rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1.25rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1.25rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1.25rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1.25rem !important; }
  .p-md-3 {
    padding: 1.875rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.875rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.875rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.875rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.875rem !important; }
  .p-md-4 {
    padding: 2.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2.5rem !important; }
  .p-md-5 {
    padding: 3.125rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3.125rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3.125rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3.125rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3.125rem !important; }
  .p-md-half {
    padding: 0.3125rem !important; }
  .pt-md-half,
  .py-md-half {
    padding-top: 0.3125rem !important; }
  .pr-md-half,
  .px-md-half {
    padding-right: 0.3125rem !important; }
  .pb-md-half,
  .py-md-half {
    padding-bottom: 0.3125rem !important; }
  .pl-md-half,
  .px-md-half {
    padding-left: 0.3125rem !important; }
  .m-md-n1 {
    margin: -0.625rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.625rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.625rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.625rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.625rem !important; }
  .m-md-n2 {
    margin: -1.25rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -1.25rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -1.25rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -1.25rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -1.25rem !important; }
  .m-md-n3 {
    margin: -1.875rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1.875rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1.875rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1.875rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1.875rem !important; }
  .m-md-n4 {
    margin: -2.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -2.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -2.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -2.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -2.5rem !important; }
  .m-md-n5 {
    margin: -3.125rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3.125rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3.125rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3.125rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3.125rem !important; }
  .m-md-nhalf {
    margin: -0.3125rem !important; }
  .mt-md-nhalf,
  .my-md-nhalf {
    margin-top: -0.3125rem !important; }
  .mr-md-nhalf,
  .mx-md-nhalf {
    margin-right: -0.3125rem !important; }
  .mb-md-nhalf,
  .my-md-nhalf {
    margin-bottom: -0.3125rem !important; }
  .ml-md-nhalf,
  .mx-md-nhalf {
    margin-left: -0.3125rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.625rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.625rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.625rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.625rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.625rem !important; }
  .m-lg-2 {
    margin: 1.25rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1.25rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1.25rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1.25rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1.25rem !important; }
  .m-lg-3 {
    margin: 1.875rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.875rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.875rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.875rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.875rem !important; }
  .m-lg-4 {
    margin: 2.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2.5rem !important; }
  .m-lg-5 {
    margin: 3.125rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3.125rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3.125rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3.125rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3.125rem !important; }
  .m-lg-half {
    margin: 0.3125rem !important; }
  .mt-lg-half,
  .my-lg-half {
    margin-top: 0.3125rem !important; }
  .mr-lg-half,
  .mx-lg-half {
    margin-right: 0.3125rem !important; }
  .mb-lg-half,
  .my-lg-half {
    margin-bottom: 0.3125rem !important; }
  .ml-lg-half,
  .mx-lg-half {
    margin-left: 0.3125rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.625rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.625rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.625rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.625rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.625rem !important; }
  .p-lg-2 {
    padding: 1.25rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1.25rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1.25rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1.25rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1.25rem !important; }
  .p-lg-3 {
    padding: 1.875rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.875rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.875rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.875rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.875rem !important; }
  .p-lg-4 {
    padding: 2.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2.5rem !important; }
  .p-lg-5 {
    padding: 3.125rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3.125rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3.125rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3.125rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3.125rem !important; }
  .p-lg-half {
    padding: 0.3125rem !important; }
  .pt-lg-half,
  .py-lg-half {
    padding-top: 0.3125rem !important; }
  .pr-lg-half,
  .px-lg-half {
    padding-right: 0.3125rem !important; }
  .pb-lg-half,
  .py-lg-half {
    padding-bottom: 0.3125rem !important; }
  .pl-lg-half,
  .px-lg-half {
    padding-left: 0.3125rem !important; }
  .m-lg-n1 {
    margin: -0.625rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.625rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.625rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.625rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.625rem !important; }
  .m-lg-n2 {
    margin: -1.25rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -1.25rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -1.25rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -1.25rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -1.25rem !important; }
  .m-lg-n3 {
    margin: -1.875rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1.875rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1.875rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1.875rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1.875rem !important; }
  .m-lg-n4 {
    margin: -2.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -2.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -2.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -2.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -2.5rem !important; }
  .m-lg-n5 {
    margin: -3.125rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3.125rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3.125rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3.125rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3.125rem !important; }
  .m-lg-nhalf {
    margin: -0.3125rem !important; }
  .mt-lg-nhalf,
  .my-lg-nhalf {
    margin-top: -0.3125rem !important; }
  .mr-lg-nhalf,
  .mx-lg-nhalf {
    margin-right: -0.3125rem !important; }
  .mb-lg-nhalf,
  .my-lg-nhalf {
    margin-bottom: -0.3125rem !important; }
  .ml-lg-nhalf,
  .mx-lg-nhalf {
    margin-left: -0.3125rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.625rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.625rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.625rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.625rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.625rem !important; }
  .m-xl-2 {
    margin: 1.25rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1.25rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1.25rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1.25rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1.25rem !important; }
  .m-xl-3 {
    margin: 1.875rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.875rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.875rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.875rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.875rem !important; }
  .m-xl-4 {
    margin: 2.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 2.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 2.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 2.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 2.5rem !important; }
  .m-xl-5 {
    margin: 3.125rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3.125rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3.125rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3.125rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3.125rem !important; }
  .m-xl-half {
    margin: 0.3125rem !important; }
  .mt-xl-half,
  .my-xl-half {
    margin-top: 0.3125rem !important; }
  .mr-xl-half,
  .mx-xl-half {
    margin-right: 0.3125rem !important; }
  .mb-xl-half,
  .my-xl-half {
    margin-bottom: 0.3125rem !important; }
  .ml-xl-half,
  .mx-xl-half {
    margin-left: 0.3125rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.625rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.625rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.625rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.625rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.625rem !important; }
  .p-xl-2 {
    padding: 1.25rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1.25rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1.25rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1.25rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1.25rem !important; }
  .p-xl-3 {
    padding: 1.875rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.875rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.875rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.875rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.875rem !important; }
  .p-xl-4 {
    padding: 2.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 2.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 2.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 2.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 2.5rem !important; }
  .p-xl-5 {
    padding: 3.125rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3.125rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3.125rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3.125rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3.125rem !important; }
  .p-xl-half {
    padding: 0.3125rem !important; }
  .pt-xl-half,
  .py-xl-half {
    padding-top: 0.3125rem !important; }
  .pr-xl-half,
  .px-xl-half {
    padding-right: 0.3125rem !important; }
  .pb-xl-half,
  .py-xl-half {
    padding-bottom: 0.3125rem !important; }
  .pl-xl-half,
  .px-xl-half {
    padding-left: 0.3125rem !important; }
  .m-xl-n1 {
    margin: -0.625rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.625rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.625rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.625rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.625rem !important; }
  .m-xl-n2 {
    margin: -1.25rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -1.25rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -1.25rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -1.25rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -1.25rem !important; }
  .m-xl-n3 {
    margin: -1.875rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1.875rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1.875rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1.875rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1.875rem !important; }
  .m-xl-n4 {
    margin: -2.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -2.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -2.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -2.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -2.5rem !important; }
  .m-xl-n5 {
    margin: -3.125rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3.125rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3.125rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3.125rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3.125rem !important; }
  .m-xl-nhalf {
    margin: -0.3125rem !important; }
  .mt-xl-nhalf,
  .my-xl-nhalf {
    margin-top: -0.3125rem !important; }
  .mr-xl-nhalf,
  .mx-xl-nhalf {
    margin-right: -0.3125rem !important; }
  .mb-xl-nhalf,
  .my-xl-nhalf {
    margin-bottom: -0.3125rem !important; }
  .ml-xl-nhalf,
  .mx-xl-nhalf {
    margin-left: -0.3125rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate, .inspiration-post-card__title, .inspiration-post-card__date, .inspiration-post-card__metrics {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase, .inspiration-post-card__campaign, .inspiration-post-card__metric {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 500 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #27b0cc !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #1b798c !important; }

.text-secondary {
  color: #4513bb !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #2b0c76 !important; }

.text-success {
  color: #27cc98 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1b8c68 !important; }

.text-info {
  color: #0095f1 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0066a5 !important; }

.text-warning {
  color: #f0b954 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #e49a14 !important; }

.text-danger {
  color: #f25270 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #e61139 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #6c757c !important; }

.text-muted {
  color: #6c757c !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

* {
  -webkit-font-smoothing: antialiased; }

small,
.small {
  font-size: 0.875rem; }

.text-lg {
  font-size: 1.25rem !important; }

.text-sm {
  font-size: 0.875rem !important; }

.text-xs {
  font-size: 0.75rem !important; }

.text-xxs {
  font-size: 0.5rem !important; }

.text-gray-100 {
  color: #f8f9fa !important; }

a.text-gray-100:hover, a.text-gray-100:focus {
  color: #cbd3da !important; }

.text-gray-200 {
  color: #e9ecef !important; }

a.text-gray-200:hover, a.text-gray-200:focus {
  color: #bdc6cf !important; }

.text-gray-300 {
  color: #dee2e6 !important; }

a.text-gray-300:hover, a.text-gray-300:focus {
  color: #b2bcc5 !important; }

.text-gray-400 {
  color: #ced4da !important; }

a.text-gray-400:hover, a.text-gray-400:focus {
  color: #a2aeb9 !important; }

.text-gray-500 {
  color: #adb5bd !important; }

a.text-gray-500:hover, a.text-gray-500:focus {
  color: #838f9b !important; }

.text-gray-600 {
  color: #6c757c !important; }

a.text-gray-600:hover, a.text-gray-600:focus {
  color: #484e53 !important; }

.text-gray-700 {
  color: #495057 !important; }

a.text-gray-700:hover, a.text-gray-700:focus {
  color: #262a2d !important; }

.text-gray-800 {
  color: #343a40 !important; }

a.text-gray-800:hover, a.text-gray-800:focus {
  color: #121416 !important; }

.text-gray-900 {
  color: #212529 !important; }

a.text-gray-900:hover, a.text-gray-900:focus {
  color: black !important; }

.text-underline {
  text-decoration: underline !important; }

.table {
  width: 100%;
  background-color: #fff;
  color: #212529;
  font-size: 0.875rem; }
  .table th {
    color: #212529;
    font-weight: 500; }
  .table th,
  .table td {
    padding: 0.9375rem 1.875rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    font-size: 1rem;
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 1px solid #dee2e6; }
  .table .table {
    background-color: #f8f9fa; }

.table-sm th,
.table-sm td {
  padding: 0.625rem 1.25rem; }

.table-lg th,
.table-lg td {
  padding: 1.25rem 2.5rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 1px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f9fa; }

.table-hover tbody tr:hover {
  background-color: #e9ecef; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

label {
  color: #212529;
  font-weight: 500; }

.form-control {
  font-weight: 300; }
  .form-control:hover:not(:focus):not(.focus):not(:disabled):not(.is-invalid):not(.is-valid) {
    border-color: #adb5bd; }

.form-control-sm,
.form-control-lg {
  font-size: 1rem; }

.form-text {
  margin-top: 0.625rem;
  font-weight: 300;
  font-size: 0.875rem; }

.form-check {
  padding-left: 0; }

.form-check-inline .form-check-input {
  position: absolute; }

.form-check-label {
  position: relative;
  display: flex;
  align-items: center;
  color: #6c757c;
  font-weight: 300;
  line-height: 1; }
  .form-check-label::before, .form-check-label::after {
    display: block;
    height: 1rem;
    width: 1rem;
    flex-shrink: 0;
    cursor: pointer; }
  .form-check-label::before {
    content: '';
    border-color: #ced4da;
    border-radius: 0.15625rem;
    border-style: solid;
    border-width: 1px; }
  .form-check-label:not(:empty)::before {
    margin-right: 0.625rem; }

.form-check-input {
  opacity: 0; }
  .form-check-input:checked ~ .form-check-label {
    color: #212529; }
    .form-check-input:checked ~ .form-check-label::before {
      background-color: #27b0cc; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757c; }
    .form-check-input:disabled ~ .form-check-label::before {
      background-color: #f8f9fa;
      cursor: default; }

.form-check-input[type='checkbox']:checked ~ .form-check-label::before {
  border-color: #27b0cc; }

.form-check-input[type='checkbox']:checked ~ .form-check-label::after {
  content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='white'><path fill-rule='evenodd' d='M10.5118 18.5821a1.4435 1.4435 0 0 1-2.0315 0L3.4252 13.475c-.567-.5571-.567-1.4857 0-2.0429a1.4435 1.4435 0 0 1 2.0315 0l4.0157 4.0858 9.071-9.1a1.4435 1.4435 0 0 1 2.0314 0c.567.5571.567 1.4857 0 2.0428l-10.063 10.1214z' /></svg>");
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  transform: translate3d(0, -52%, 0); }

.form-check-input[type='radio'] ~ .form-check-label::before {
  border-radius: 50%; }

.form-check-input[type='radio']:checked ~ .form-check-label::before {
  box-shadow: inset 0 0 0 2px #fff; }

.btn-gray-100 {
  background-color: #f8f9fa;
  color: #212529;
  border-color: #f8f9fa; }
  .btn-gray-100:hover {
    background-color: #e2e6ea;
    color: #212529;
    border-color: #dae0e5; }
  .btn-gray-100:focus, .btn-gray-100.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-gray-100.disabled, .btn-gray-100:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-gray-100:not(:disabled):not(.disabled):active, .btn-gray-100:not(:disabled):not(.disabled).active,
  .show > .btn-gray-100.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }

.btn-outline-gray-100 {
  color: #f8f9fa;
  background-color: #fff;
  background-image: none;
  border-color: #fdfdfe; }
  .btn-outline-gray-100:hover {
    color: #f8f9fa;
    background-color: #fff;
    border-color: #f8f9fa; }
  .btn-outline-gray-100.disabled, .btn-outline-gray-100:disabled, .btn-outline-gray-100.disabled:hover {
    color: #f8f9fa;
    background-color: transparent;
    border-color: #fdfdfe; }
  .btn-outline-gray-100:not(:disabled):not(.disabled):active, .btn-outline-gray-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-100.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #f8f9fa; }

.btn-gray-200 {
  background-color: #e9ecef;
  color: #212529;
  border-color: #e9ecef; }
  .btn-gray-200:hover {
    background-color: #d3d9df;
    color: #212529;
    border-color: #cbd3da; }
  .btn-gray-200:focus, .btn-gray-200.focus {
    box-shadow: 0 0 0 0.2rem rgba(203, 206, 209, 0.5); }
  .btn-gray-200.disabled, .btn-gray-200:disabled {
    color: #212529;
    background-color: #e9ecef;
    border-color: #e9ecef; }
  .btn-gray-200:not(:disabled):not(.disabled):active, .btn-gray-200:not(:disabled):not(.disabled).active,
  .show > .btn-gray-200.dropdown-toggle {
    color: #212529;
    background-color: #cbd3da;
    border-color: #c4ccd4; }

.btn-outline-gray-200 {
  color: #e9ecef;
  background-color: #fff;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-gray-200:hover {
    color: #e9ecef;
    background-color: #fff;
    border-color: #e9ecef; }
  .btn-outline-gray-200.disabled, .btn-outline-gray-200:disabled, .btn-outline-gray-200.disabled:hover {
    color: #e9ecef;
    background-color: transparent;
    border-color: #f8f9fa; }
  .btn-outline-gray-200:not(:disabled):not(.disabled):active, .btn-outline-gray-200:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-200.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #e9ecef; }

.btn-gray-300 {
  background-color: #dee2e6;
  color: #212529;
  border-color: #dee2e6; }
  .btn-gray-300:hover {
    background-color: #c8cfd6;
    color: #212529;
    border-color: #c1c9d0; }
  .btn-gray-300:focus, .btn-gray-300.focus {
    box-shadow: 0 0 0 0.2rem rgba(194, 198, 202, 0.5); }
  .btn-gray-300.disabled, .btn-gray-300:disabled {
    color: #212529;
    background-color: #dee2e6;
    border-color: #dee2e6; }
  .btn-gray-300:not(:disabled):not(.disabled):active, .btn-gray-300:not(:disabled):not(.disabled).active,
  .show > .btn-gray-300.dropdown-toggle {
    color: #212529;
    background-color: #c1c9d0;
    border-color: #bac2cb; }

.btn-outline-gray-300 {
  color: #dee2e6;
  background-color: #fff;
  background-image: none;
  border-color: #f5f6f8; }
  .btn-outline-gray-300:hover {
    color: #dee2e6;
    background-color: #fff;
    border-color: #dee2e6; }
  .btn-outline-gray-300.disabled, .btn-outline-gray-300:disabled, .btn-outline-gray-300.disabled:hover {
    color: #dee2e6;
    background-color: transparent;
    border-color: #f5f6f8; }
  .btn-outline-gray-300:not(:disabled):not(.disabled):active, .btn-outline-gray-300:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-300.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #dee2e6; }

.btn-gray-400 {
  background-color: #ced4da;
  color: #212529;
  border-color: #ced4da; }
  .btn-gray-400:hover {
    background-color: #b8c1ca;
    color: #212529;
    border-color: #b1bbc4; }
  .btn-gray-400:focus, .btn-gray-400.focus {
    box-shadow: 0 0 0 0.2rem rgba(180, 186, 191, 0.5); }
  .btn-gray-400.disabled, .btn-gray-400:disabled {
    color: #212529;
    background-color: #ced4da;
    border-color: #ced4da; }
  .btn-gray-400:not(:disabled):not(.disabled):active, .btn-gray-400:not(:disabled):not(.disabled).active,
  .show > .btn-gray-400.dropdown-toggle {
    color: #212529;
    background-color: #b1bbc4;
    border-color: #aab4bf; }

.btn-outline-gray-400 {
  color: #ced4da;
  background-color: #fff;
  background-image: none;
  border-color: #f0f2f4; }
  .btn-outline-gray-400:hover {
    color: #ced4da;
    background-color: #fff;
    border-color: #ced4da; }
  .btn-outline-gray-400.disabled, .btn-outline-gray-400:disabled, .btn-outline-gray-400.disabled:hover {
    color: #ced4da;
    background-color: transparent;
    border-color: #f0f2f4; }
  .btn-outline-gray-400:not(:disabled):not(.disabled):active, .btn-outline-gray-400:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-400.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #ced4da; }

.btn-gray-500 {
  background-color: #adb5bd;
  color: #212529;
  border-color: #adb5bd; }
  .btn-gray-500:hover {
    background-color: #98a2ac;
    color: #212529;
    border-color: #919ca6; }
  .btn-gray-500:focus, .btn-gray-500.focus {
    box-shadow: 0 0 0 0.2rem rgba(152, 159, 167, 0.5); }
  .btn-gray-500.disabled, .btn-gray-500:disabled {
    color: #212529;
    background-color: #adb5bd;
    border-color: #adb5bd; }
  .btn-gray-500:not(:disabled):not(.disabled):active, .btn-gray-500:not(:disabled):not(.disabled).active,
  .show > .btn-gray-500.dropdown-toggle {
    color: #212529;
    background-color: #919ca6;
    border-color: #8a95a1; }

.btn-outline-gray-500 {
  color: #adb5bd;
  background-color: #fff;
  background-image: none;
  border-color: #e6e9eb; }
  .btn-outline-gray-500:hover {
    color: #adb5bd;
    background-color: #fff;
    border-color: #adb5bd; }
  .btn-outline-gray-500.disabled, .btn-outline-gray-500:disabled, .btn-outline-gray-500.disabled:hover {
    color: #adb5bd;
    background-color: transparent;
    border-color: #e6e9eb; }
  .btn-outline-gray-500:not(:disabled):not(.disabled):active, .btn-outline-gray-500:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-500.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #adb5bd; }

.btn-gray-600 {
  background-color: #6c757c;
  color: #fff;
  border-color: #6c757c; }
  .btn-gray-600:hover {
    background-color: #5a6268;
    color: #fff;
    border-color: #545b61; }
  .btn-gray-600:focus, .btn-gray-600.focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 144, 0.5); }
  .btn-gray-600.disabled, .btn-gray-600:disabled {
    color: #fff;
    background-color: #6c757c;
    border-color: #6c757c; }
  .btn-gray-600:not(:disabled):not(.disabled):active, .btn-gray-600:not(:disabled):not(.disabled).active,
  .show > .btn-gray-600.dropdown-toggle {
    color: #fff;
    background-color: #545b61;
    border-color: #4e555a; }

.btn-outline-gray-600 {
  color: #6c757c;
  background-color: #fff;
  background-image: none;
  border-color: #d2d6d8; }
  .btn-outline-gray-600:hover {
    color: #6c757c;
    background-color: #fff;
    border-color: #6c757c; }
  .btn-outline-gray-600.disabled, .btn-outline-gray-600:disabled, .btn-outline-gray-600.disabled:hover {
    color: #6c757c;
    background-color: transparent;
    border-color: #d2d6d8; }
  .btn-outline-gray-600:not(:disabled):not(.disabled):active, .btn-outline-gray-600:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-600.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #6c757c; }

.btn-gray-700 {
  background-color: #495057;
  color: #fff;
  border-color: #495057; }
  .btn-gray-700:hover {
    background-color: #383d42;
    color: #fff;
    border-color: #32373b; }
  .btn-gray-700:focus, .btn-gray-700.focus {
    box-shadow: 0 0 0 0.2rem rgba(100, 106, 112, 0.5); }
  .btn-gray-700.disabled, .btn-gray-700:disabled {
    color: #fff;
    background-color: #495057;
    border-color: #495057; }
  .btn-gray-700:not(:disabled):not(.disabled):active, .btn-gray-700:not(:disabled):not(.disabled).active,
  .show > .btn-gray-700.dropdown-toggle {
    color: #fff;
    background-color: #32373b;
    border-color: #2c3034; }

.btn-outline-gray-700 {
  color: #495057;
  background-color: #fff;
  background-image: none;
  border-color: #c6cbcf; }
  .btn-outline-gray-700:hover {
    color: #495057;
    background-color: #fff;
    border-color: #495057; }
  .btn-outline-gray-700.disabled, .btn-outline-gray-700:disabled, .btn-outline-gray-700.disabled:hover {
    color: #495057;
    background-color: transparent;
    border-color: #c6cbcf; }
  .btn-outline-gray-700:not(:disabled):not(.disabled):active, .btn-outline-gray-700:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-700.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #495057; }

.btn-gray-800 {
  background-color: #343a40;
  color: #fff;
  border-color: #343a40; }
  .btn-gray-800:hover {
    background-color: #23272b;
    color: #fff;
    border-color: #1d2124; }
  .btn-gray-800:focus, .btn-gray-800.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-gray-800.disabled, .btn-gray-800:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-gray-800:not(:disabled):not(.disabled):active, .btn-gray-800:not(:disabled):not(.disabled).active,
  .show > .btn-gray-800.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }

.btn-outline-gray-800 {
  color: #343a40;
  background-color: #fff;
  background-image: none;
  border-color: #bec4ca; }
  .btn-outline-gray-800:hover {
    color: #343a40;
    background-color: #fff;
    border-color: #343a40; }
  .btn-outline-gray-800.disabled, .btn-outline-gray-800:disabled, .btn-outline-gray-800.disabled:hover {
    color: #343a40;
    background-color: transparent;
    border-color: #bec4ca; }
  .btn-outline-gray-800:not(:disabled):not(.disabled):active, .btn-outline-gray-800:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-800.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #343a40; }

.btn-gray-900 {
  background-color: #212529;
  color: #fff;
  border-color: #212529; }
  .btn-gray-900:hover {
    background-color: #101214;
    color: #fff;
    border-color: #0a0c0d; }
  .btn-gray-900:focus, .btn-gray-900.focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }
  .btn-gray-900.disabled, .btn-gray-900:disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .btn-gray-900:not(:disabled):not(.disabled):active, .btn-gray-900:not(:disabled):not(.disabled).active,
  .show > .btn-gray-900.dropdown-toggle {
    color: #fff;
    background-color: #0a0c0d;
    border-color: #050506; }

.btn-outline-gray-900 {
  color: #212529;
  background-color: #fff;
  background-image: none;
  border-color: #b7bec5; }
  .btn-outline-gray-900:hover {
    color: #212529;
    background-color: #fff;
    border-color: #212529; }
  .btn-outline-gray-900.disabled, .btn-outline-gray-900:disabled, .btn-outline-gray-900.disabled:hover {
    color: #212529;
    background-color: transparent;
    border-color: #b7bec5; }
  .btn-outline-gray-900:not(:disabled):not(.disabled):active, .btn-outline-gray-900:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-900.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #212529; }

.btn-white {
  background-color: #fff;
  color: #212529;
  border-color: #fff; }
  .btn-white:hover {
    background-color: #ececec;
    color: #212529;
    border-color: #e6e6e6; }
  .btn-white:focus, .btn-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
  .btn-white:hover, .btn-white:focus {
    background-color: #fff;
    border-color: #fff; }

.btn-facebook {
  background-color: #1678f2;
  color: #fff;
  border-color: #1678f2; }
  .btn-facebook:hover {
    background-color: #0c66d6;
    color: #fff;
    border-color: #0b60ca; }
  .btn-facebook:focus, .btn-facebook.focus {
    box-shadow: 0 0 0 0.2rem rgba(57, 140, 244, 0.5); }
  .btn-facebook.disabled, .btn-facebook:disabled {
    color: #fff;
    background-color: #1678f2;
    border-color: #1678f2; }
  .btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active,
  .show > .btn-facebook.dropdown-toggle {
    color: #fff;
    background-color: #0b60ca;
    border-color: #0b5abe; }

.btn-outline-facebook {
  background-color: #1678f2;
  color: #fff;
  border-color: #1678f2; }
  .btn-outline-facebook:hover {
    background-color: #0c66d6;
    color: #fff;
    border-color: #0b60ca; }
  .btn-outline-facebook:focus, .btn-outline-facebook.focus {
    box-shadow: 0 0 0 0.2rem rgba(57, 140, 244, 0.5); }
  .btn-outline-facebook.disabled, .btn-outline-facebook:disabled {
    color: #fff;
    background-color: #1678f2;
    border-color: #1678f2; }
  .btn-outline-facebook:not(:disabled):not(.disabled):active, .btn-outline-facebook:not(:disabled):not(.disabled).active,
  .show > .btn-outline-facebook.dropdown-toggle {
    color: #fff;
    background-color: #0b60ca;
    border-color: #0b5abe; }

.btn-twitter {
  background-color: #1da1f2;
  color: #fff;
  border-color: #1da1f2; }
  .btn-twitter:hover {
    background-color: #0d8ddc;
    color: #fff;
    border-color: #0c85d0; }
  .btn-twitter:focus, .btn-twitter.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5); }
  .btn-twitter.disabled, .btn-twitter:disabled {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2; }
  .btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active,
  .show > .btn-twitter.dropdown-toggle {
    color: #fff;
    background-color: #0c85d0;
    border-color: #0b7ec4; }

.btn-outline-twitter {
  background-color: #1da1f2;
  color: #fff;
  border-color: #1da1f2; }
  .btn-outline-twitter:hover {
    background-color: #0d8ddc;
    color: #fff;
    border-color: #0c85d0; }
  .btn-outline-twitter:focus, .btn-outline-twitter.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5); }
  .btn-outline-twitter.disabled, .btn-outline-twitter:disabled {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2; }
  .btn-outline-twitter:not(:disabled):not(.disabled):active, .btn-outline-twitter:not(:disabled):not(.disabled).active,
  .show > .btn-outline-twitter.dropdown-toggle {
    color: #fff;
    background-color: #0c85d0;
    border-color: #0b7ec4; }

.btn-linkedin {
  background-color: #2767b2;
  color: #fff;
  border-color: #2767b2; }
  .btn-linkedin:hover {
    background-color: #205593;
    color: #fff;
    border-color: #1e4f88; }
  .btn-linkedin:focus, .btn-linkedin.focus {
    box-shadow: 0 0 0 0.2rem rgba(71, 126, 190, 0.5); }
  .btn-linkedin.disabled, .btn-linkedin:disabled {
    color: #fff;
    background-color: #2767b2;
    border-color: #2767b2; }
  .btn-linkedin:not(:disabled):not(.disabled):active, .btn-linkedin:not(:disabled):not(.disabled).active,
  .show > .btn-linkedin.dropdown-toggle {
    color: #fff;
    background-color: #1e4f88;
    border-color: #1c497e; }

.btn-outline-linkedin {
  background-color: #2767b2;
  color: #fff;
  border-color: #2767b2; }
  .btn-outline-linkedin:hover {
    background-color: #205593;
    color: #fff;
    border-color: #1e4f88; }
  .btn-outline-linkedin:focus, .btn-outline-linkedin.focus {
    box-shadow: 0 0 0 0.2rem rgba(71, 126, 190, 0.5); }
  .btn-outline-linkedin.disabled, .btn-outline-linkedin:disabled {
    color: #fff;
    background-color: #2767b2;
    border-color: #2767b2; }
  .btn-outline-linkedin:not(:disabled):not(.disabled):active, .btn-outline-linkedin:not(:disabled):not(.disabled).active,
  .show > .btn-outline-linkedin.dropdown-toggle {
    color: #fff;
    background-color: #1e4f88;
    border-color: #1c497e; }

.btn-twitter {
  color: #fff; }

.btn .icon {
  pointer-events: none; }

.btn-icon {
  padding: 0.9375rem;
  border-radius: 0.3125rem; }
  .btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
    padding: 0.625rem;
    border-radius: 0.3125rem; }
  .btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
    padding: 1.25rem;
    border-radius: 0.3125rem; }
  .btn-icon .icon {
    margin: 0; }
  .btn-icon-left, .btn-icon-right {
    display: flex;
    align-items: center;
    justify-content: center; }
  .btn-icon-left {
    flex-direction: row-reverse; }
    .btn-icon-left .icon {
      margin-right: 0.625rem; }
  .btn-icon-right .icon {
    margin-left: 0.625rem; }

.btn.disabled,
.btn:hover.disabled {
  background-color: #e9ecef;
  border-color: #ced4da;
  color: #6c757c; }

.input-group-prepend .btn,
.input-group-append .btn {
  display: flex; }

.dropdown-menu {
  min-width: 100%;
  padding: 0 0;
  overflow: hidden; }

.dropdown-item {
  display: flex;
  align-items: center;
  font-weight: 300;
  border-bottom: 1px solid #e9ecef;
  line-height: 1; }
  .dropdown-item:last-child {
    border: none; }

.dropdown-menu-dark {
  background-color: #343a40; }
  .dropdown-menu-dark .dropdown-item {
    color: #fff;
    border-color: #495057; }
    .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
      color: #f2f2f2;
      text-decoration: none;
      background-color: #495057;
      cursor: pointer; }
    .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
      color: #fff;
      text-decoration: none;
      background-color: #495057; }
    .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
      color: #6c757c;
      background-color: transparent; }

.dropdown-header {
  padding: 1.25rem 1.25rem 0.625rem 1.25rem;
  font-size: 0.75rem;
  text-transform: uppercase; }

.dropdown-footer {
  display: block;
  padding: 1.25rem 1.25rem;
  color: #adb5bd;
  font-size: 0.75rem;
  cursor: default; }
  .dropdown-footer:hover, .dropdown-footer:focus {
    color: #adb5bd;
    text-decoration: none; }

.nav-link {
  color: #6c757c; }
  .nav-link.active {
    color: #27b0cc; }
  .nav-link.disabled {
    cursor: default; }

.nav-tabs .nav-item {
  margin-right: 0.625rem; }
  .nav-tabs .nav-item:last-of-type {
    margin-right: 0; }

.nav-tabs .nav-link {
  color: #6c757c;
  border-color: #ced4da;
  border-top-width: 2px;
  font-weight: 300; }
  .nav-tabs .nav-link.active {
    color: #495057;
    border-top-color: #27b0cc;
    font-weight: 500; }
    .nav-tabs .nav-link.active.nav-link-primary {
      border-top-color: #27b0cc; }
    .nav-tabs .nav-link.active.nav-link-secondary {
      border-top-color: #4513bb; }
    .nav-tabs .nav-link.active.nav-link-success {
      border-top-color: #27cc98; }
    .nav-tabs .nav-link.active.nav-link-info {
      border-top-color: #0095f1; }
    .nav-tabs .nav-link.active.nav-link-warning {
      border-top-color: #f0b954; }
    .nav-tabs .nav-link.active.nav-link-danger {
      border-top-color: #f25270; }
    .nav-tabs .nav-link.active.nav-link-light {
      border-top-color: #f8f9fa; }
    .nav-tabs .nav-link.active.nav-link-dark {
      border-top-color: #343a40; }
  .nav-tabs .nav-link.disabled {
    border-color: #ced4da; }

.nav-pills .nav-link {
  color: #6c757c; }
  .nav-pills .nav-link.active {
    font-weight: 500; }
    .nav-pills .nav-link.active.nav-link-primary {
      background-color: #27b0cc; }
    .nav-pills .nav-link.active.nav-link-secondary {
      background-color: #4513bb; }
    .nav-pills .nav-link.active.nav-link-success {
      background-color: #27cc98; }
    .nav-pills .nav-link.active.nav-link-info {
      background-color: #0095f1; }
    .nav-pills .nav-link.active.nav-link-warning {
      background-color: #f0b954; }
    .nav-pills .nav-link.active.nav-link-danger {
      background-color: #f25270; }
    .nav-pills .nav-link.active.nav-link-light {
      background-color: #f8f9fa; }
    .nav-pills .nav-link.active.nav-link-dark {
      background-color: #343a40; }
  .nav-pills .nav-link.disabled {
    color: #ced4da; }

.pagination {
  border-radius: 0.3125rem; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem; }

.page-item .page-link {
  color: #212529; }

.page-item.active .page-link {
  font-weight: 500; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem; }

.badge-outline-primary {
  border-style: solid;
  border-width: 1px;
  border-color: #bce9f2;
  color: #27b0cc; }

.badge-outline-secondary {
  border-style: solid;
  border-width: 1px;
  border-color: #c2acf7;
  color: #4513bb; }

.badge-outline-success {
  border-style: solid;
  border-width: 1px;
  border-color: #bcf2e1;
  color: #27cc98; }

.badge-outline-info {
  border-style: solid;
  border-width: 1px;
  border-color: #aee0ff;
  color: #0095f1; }

.badge-outline-warning {
  border-style: solid;
  border-width: 1px;
  border-color: #fbeacc;
  color: #f0b954; }

.badge-outline-danger {
  border-style: solid;
  border-width: 1px;
  border-color: #fbcbd4;
  color: #f25270; }

.badge-outline-light {
  border-style: solid;
  border-width: 1px;
  border-color: #fdfdfe;
  color: #f8f9fa; }

.badge-outline-dark {
  border-style: solid;
  border-width: 1px;
  border-color: #bec4ca;
  color: #343a40; }

.badge-gray-100 {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-gray-100:hover, a.badge-gray-100:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-gray-100:focus, a.badge-gray-100.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-outline-gray-100 {
  border-style: solid;
  border-width: 1px;
  border-color: #fdfdfe;
  color: #f8f9fa; }

.badge-gray-200 {
  color: #212529;
  background-color: #e9ecef; }
  a.badge-gray-200:hover, a.badge-gray-200:focus {
    color: #212529;
    background-color: #cbd3da; }
  a.badge-gray-200:focus, a.badge-gray-200.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5); }

.badge-outline-gray-200 {
  border-style: solid;
  border-width: 1px;
  border-color: #f8f9fa;
  color: #e9ecef; }

.badge-gray-300 {
  color: #212529;
  background-color: #dee2e6; }
  a.badge-gray-300:hover, a.badge-gray-300:focus {
    color: #212529;
    background-color: #c1c9d0; }
  a.badge-gray-300:focus, a.badge-gray-300.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5); }

.badge-outline-gray-300 {
  border-style: solid;
  border-width: 1px;
  border-color: #f5f6f8;
  color: #dee2e6; }

.badge-gray-400 {
  color: #212529;
  background-color: #ced4da; }
  a.badge-gray-400:hover, a.badge-gray-400:focus {
    color: #212529;
    background-color: #b1bbc4; }
  a.badge-gray-400:focus, a.badge-gray-400.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5); }

.badge-outline-gray-400 {
  border-style: solid;
  border-width: 1px;
  border-color: #f0f2f4;
  color: #ced4da; }

.badge-gray-500 {
  color: #212529;
  background-color: #adb5bd; }
  a.badge-gray-500:hover, a.badge-gray-500:focus {
    color: #212529;
    background-color: #919ca6; }
  a.badge-gray-500:focus, a.badge-gray-500.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5); }

.badge-outline-gray-500 {
  border-style: solid;
  border-width: 1px;
  border-color: #e6e9eb;
  color: #adb5bd; }

.badge-gray-600 {
  color: #fff;
  background-color: #6c757c; }
  a.badge-gray-600:hover, a.badge-gray-600:focus {
    color: #fff;
    background-color: #545b61; }
  a.badge-gray-600:focus, a.badge-gray-600.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 124, 0.5); }

.badge-outline-gray-600 {
  border-style: solid;
  border-width: 1px;
  border-color: #d2d6d8;
  color: #6c757c; }

.badge-gray-700 {
  color: #fff;
  background-color: #495057; }
  a.badge-gray-700:hover, a.badge-gray-700:focus {
    color: #fff;
    background-color: #32373b; }
  a.badge-gray-700:focus, a.badge-gray-700.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(73, 80, 87, 0.5); }

.badge-outline-gray-700 {
  border-style: solid;
  border-width: 1px;
  border-color: #c6cbcf;
  color: #495057; }

.badge-gray-800 {
  color: #fff;
  background-color: #343a40; }
  a.badge-gray-800:hover, a.badge-gray-800:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-gray-800:focus, a.badge-gray-800.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.badge-outline-gray-800 {
  border-style: solid;
  border-width: 1px;
  border-color: #bec4ca;
  color: #343a40; }

.badge-gray-900 {
  color: #fff;
  background-color: #212529; }
  a.badge-gray-900:hover, a.badge-gray-900:focus {
    color: #fff;
    background-color: #0a0c0d; }
  a.badge-gray-900:focus, a.badge-gray-900.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }

.badge-outline-gray-900 {
  border-style: solid;
  border-width: 1px;
  border-color: #b7bec5;
  color: #212529; }

.bg-gray-100 {
  background-color: #f8f9fa !important; }

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #dae0e5 !important; }

.bg-gray-200 {
  background-color: #e9ecef !important; }

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #cbd3da !important; }

.bg-gray-300 {
  background-color: #dee2e6 !important; }

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #c1c9d0 !important; }

.bg-gray-400 {
  background-color: #ced4da !important; }

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #b1bbc4 !important; }

.bg-gray-500 {
  background-color: #adb5bd !important; }

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #919ca6 !important; }

.bg-gray-600 {
  background-color: #6c757c !important; }

a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #545b61 !important; }

.bg-gray-700 {
  background-color: #495057 !important; }

a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #32373b !important; }

.bg-gray-800 {
  background-color: #343a40 !important; }

a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #1d2124 !important; }

.bg-gray-900 {
  background-color: #212529 !important; }

a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: #0a0c0d !important; }

.bg-facebook {
  background-color: #1678f2 !important; }

a.bg-facebook:hover, a.bg-facebook:focus,
button.bg-facebook:hover,
button.bg-facebook:focus {
  background-color: #0b60ca !important; }

.bg-twitter {
  background-color: #1da1f2 !important; }

a.bg-twitter:hover, a.bg-twitter:focus,
button.bg-twitter:hover,
button.bg-twitter:focus {
  background-color: #0c85d0 !important; }

.bg-linkedin {
  background-color: #2767b2 !important; }

a.bg-linkedin:hover, a.bg-linkedin:focus,
button.bg-linkedin:hover,
button.bg-linkedin:focus {
  background-color: #1e4f88 !important; }

.bg-contain {
  background-size: contain; }

.bg-cover {
  background-size: cover; }

.bg-center {
  background-position: center; }

.rounded-0 {
  border-radius: 0; }

.rounded-top-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.rounded-right-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.rounded-bottom-0 {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.rounded-left-0 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.rounded-sm {
  border-radius: 0.3125rem; }

.rounded-top-sm {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem; }

.rounded-right-sm {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem; }

.rounded-bottom-sm {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem; }

.rounded-left-sm {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem; }

.rounded-lg {
  border-radius: 1.25rem; }

.rounded-top-lg {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem; }

.rounded-right-lg {
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem; }

.rounded-bottom-lg {
  border-bottom-right-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem; }

.rounded-left-lg {
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem; }

.border-gray-100 {
  border-color: #f8f9fa !important; }

.border-gray-200 {
  border-color: #e9ecef !important; }

.border-gray-300 {
  border-color: #dee2e6 !important; }

.border-gray-400 {
  border-color: #ced4da !important; }

.border-gray-500 {
  border-color: #adb5bd !important; }

.border-gray-600 {
  border-color: #6c757c !important; }

.border-gray-700 {
  border-color: #495057 !important; }

.border-gray-800 {
  border-color: #343a40 !important; }

.border-gray-900 {
  border-color: #212529 !important; }

.cursor-default {
  cursor: default !important; }

.cursor-pointer {
  cursor: pointer !important; }

.overflow-hidden {
  overflow: hidden !important; }

.text-wrap {
  word-wrap: break-word !important; }

.text-uppercase, .inspiration-post-card__campaign, .inspiration-post-card__metric {
  text-transform: uppercase !important;
  letter-spacing: 0.04em !important; }

.custom-switch .custom-control-label::after {
  background-color: #fff; }

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff; }

.custom-switch-left {
  padding-right: 3rem;
  padding-left: unset; }
  .custom-switch-left .custom-control-label::before {
    left: unset;
    right: -3rem; }
  .custom-switch-left .custom-control-label::after {
    left: unset;
    right: calc( -3rem + 2px);
    transform: translateX(-1.25rem); }
  .custom-switch-left .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(0); }

.custom-switch-top {
  padding-bottom: 1.75rem;
  padding-left: unset; }
  .custom-switch-top .custom-control-label::before {
    top: unset;
    left: 50%;
    bottom: -1.75rem;
    transform: translateX(-50%); }
  .custom-switch-top .custom-control-label::after {
    top: unset;
    left: 50%;
    bottom: calc( -1.75rem + 2px);
    transform: translateX(-1.15rem); }
  .custom-switch-top .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(0.125rem); }

.custom-switch-bottom {
  padding-top: 1.75rem;
  padding-left: unset; }
  .custom-switch-bottom .custom-control-label::before {
    bottom: unset;
    left: 50%;
    top: -1.75rem;
    transform: translateX(-50%); }
  .custom-switch-bottom .custom-control-label::after {
    bottom: unset;
    left: 50%;
    top: calc( -1.75rem + 2px);
    transform: translateX(-1.15rem); }
  .custom-switch-bottom .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(0.125rem); }

.avatar {
  width: 100%;
  height: 100%;
  position: relative; }
  .avatar-disconnected {
    opacity: 0.3; }

.avatar-inner {
  padding-right: 10%;
  padding-bottom: 10%; }

.avatar-image {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  border-radius: 50%;
  background-color: #dee2e6;
  background-size: cover;
  background-position: center; }

.avatar-provider-lg .avatar-inner {
  padding-right: 20%;
  padding-bottom: 20%; }

.avatar-provider-lg .avatar-provider {
  width: 60%;
  height: 60%; }

.avatar-provider-md .avatar-provider {
  width: 50%;
  height: 50%; }

.avatar-provider-sm .avatar-inner {
  padding-right: 0%;
  padding-bottom: 0%; }

.avatar-provider-sm .avatar-provider {
  width: 40%;
  height: 40%; }

.avatar-provider {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 50%;
  height: 50%; }
  .avatar-provider .icon {
    width: 80%;
    height: 80%; }

.avatar-provider-tick {
  background-color: #27cc98;
  border: none; }

.avatar-provider-facebook {
  background-color: #1678f2; }

.avatar-provider-twitter {
  background-color: #1da1f2; }

.avatar-provider-linkedin {
  background-color: #2767b2; }

.campaign-card-cover {
  height: 0;
  padding-bottom: 40%;
  background-size: cover;
  background-position: center;
  border-radius: 1.25rem 1.25rem 0 0; }

.campaign-template-card {
  box-shadow: 0 0 30px 0 rgba(73, 80, 87, 0.12); }
  .campaign-template-card--selected {
    box-shadow: 0 0 0 2px #27cc98, 0 0 30px 0 rgba(73, 80, 87, 0.12); }

.character-counter {
  display: flex;
  align-items: center;
  line-height: 1; }
  .character-counter .icon {
    margin-right: 0.625rem; }

.form-select {
  position: relative; }
  .form-select--is-disabled {
    pointer-events: none;
    cursor: default; }
  .form-select .tag {
    font-size: 0.875rem;
    margin: 0.15625rem; }

.form-select__control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: calc(1em + 1.875rem + 2px);
  padding: 0.9375rem 1.25rem;
  font-size: 1rem;
  line-height: 1;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-select__control {
      transition: none; } }
  .form-select__control--is-focused {
    color: #212529;
    background-color: #fff;
    border-color: #27b0cc;
    outline: 0; }
  .form-select__control--is-disabled {
    background-color: #e9ecef; }

.form-select-sm .form-select__control {
  min-height: calc(1em + 1.25rem + 2px);
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0.3125rem; }

.form-select-lg .form-select__control {
  min-height: calc(1em + 2.5rem + 2px);
  padding: 1.25rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 0.3125rem; }

.form-select.is-valid .form-select__control {
  border-color: #27cc98; }

.form-select.is-invalid .form-select__control {
  border-color: #f25270; }

.form-select__control:hover:not(.form-select__control--is-focused) {
  border-color: #adb5bd; }

.form-select__placeholder {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757c; }

.form-select__value-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1 1 auto; }

.form-select__value-container--is-multi.form-select__value-container--has-value {
  margin-top: -0.3125rem;
  margin-bottom: -0.3125rem; }

.form-select__single-value {
  color: #212529;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-overflow: ellipsis;
  white-space: nowrap; }

.form-select__indicators {
  display: flex;
  align-items: center;
  flex-shrink: 0; }
  .form-select__indicators .icon {
    margin-left: 0.625rem;
    cursor: pointer; }

.form-select__menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  min-width: 100%;
  padding: 0 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #6c757c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  overflow: hidden;
  border-radius: 0.3125rem; }

.form-select__menu-list {
  max-height: 300px;
  overflow-y: auto; }

.form-select__option,
.form-select__menu-notice {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.25rem 1.25rem;
  font-weight: 300;
  color: #212529;
  white-space: nowrap;
  border-bottom: 1px solid #e9ecef;
  line-height: 1;
  cursor: pointer; }
  .form-select__option:last-child,
  .form-select__menu-notice:last-child {
    border: none; }
  .form-select__option:hover, .form-select__option:focus,
  .form-select__menu-notice:hover,
  .form-select__menu-notice:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }

.form-select__group {
  border-bottom: 1px solid #e9ecef; }
  .form-select__group:last-child {
    border: none; }

.form-select__group-heading {
  display: block;
  margin-bottom: 0;
  padding: 1.25rem 1.25rem 0.625rem 1.25rem;
  font-size: 0.75rem;
  color: #6c757c;
  text-transform: uppercase;
  white-space: nowrap; }

.icon-primary {
  fill: #27b0cc; }
  .icon-primary:not(.disabled):not(.active):hover {
    fill: #124f5c; }

.icon-secondary {
  fill: #4513bb; }
  .icon-secondary:not(.disabled):not(.active):hover {
    fill: #1f0954; }

.icon-success {
  fill: #27cc98; }
  .icon-success:not(.disabled):not(.active):hover {
    fill: #125c44; }

.icon-info {
  fill: #0095f1; }
  .icon-info:not(.disabled):not(.active):hover {
    fill: #00436c; }

.icon-warning {
  fill: #f0b954; }
  .icon-warning:not(.disabled):not(.active):hover {
    fill: #865b0c; }

.icon-danger {
  fill: #f25270; }
  .icon-danger:not(.disabled):not(.active):hover {
    fill: #880a22; }

.icon-light {
  fill: #f8f9fa; }
  .icon-light:not(.disabled):not(.active):hover {
    fill: #5d7083; }

.icon-dark {
  fill: #343a40; }
  .icon-dark:not(.disabled):not(.active):hover {
    fill: #171a1d; }

.icon-gray-100 {
  fill: #f8f9fa; }
  .icon-gray-100:not(.disabled):not(.active):hover {
    fill: #5d7083; }

.icon-gray-200 {
  fill: #e9ecef; }
  .icon-gray-200:not(.disabled):not(.active):hover {
    fill: #596a7b; }

.icon-gray-300 {
  fill: #dee2e6; }
  .icon-gray-300:not(.disabled):not(.active):hover {
    fill: #586674; }

.icon-gray-400 {
  fill: #ced4da; }
  .icon-gray-400:not(.disabled):not(.active):hover {
    fill: #525f6d; }

.icon-gray-500 {
  fill: #adb5bd; }
  .icon-gray-500:not(.disabled):not(.active):hover {
    fill: #49515a; }

.icon-gray-600 {
  fill: #6c757c; }
  .icon-gray-600:not(.disabled):not(.active):hover {
    fill: #313538; }

.icon-gray-700 {
  fill: #495057; }
  .icon-gray-700:not(.disabled):not(.active):hover {
    fill: #212427; }

.icon-gray-800 {
  fill: #343a40; }
  .icon-gray-800:not(.disabled):not(.active):hover {
    fill: #171a1d; }

.icon-gray-900 {
  fill: #212529; }
  .icon-gray-900:not(.disabled):not(.active):hover {
    fill: #0f1112; }

.icon-white {
  fill: #fff; }
  .icon-white:not(.disabled):not(.active):hover {
    fill: #737373; }

.icon-black {
  fill: #000; }
  .icon-black:not(.disabled):not(.active):hover {
    fill: black; }

.icon-facebook {
  fill: #1678f2; }
  .icon-facebook:not(.disabled):not(.active):hover {
    fill: #063671; }

.icon-twitter {
  fill: #1da1f2; }
  .icon-twitter:not(.disabled):not(.active):hover {
    fill: #074a73; }

.icon-linkedin {
  fill: #2767b2; }
  .icon-linkedin:not(.disabled):not(.active):hover {
    fill: #122e50; }

.inspiration-post-card {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 30px 0 rgba(73, 80, 87, 0.04);
  transition: box-shadow 0.3s; }
  .inspiration-post-card:hover {
    box-shadow: 0 0 30px 0 rgba(73, 80, 87, 0.12); }
  .inspiration-post-card--draft {
    background-color: #f8f9fa;
    box-shadow: 0 0 0 2px #adb5bd, 0 0 30px 0 rgba(73, 80, 87, 0.04); }
    .inspiration-post-card--draft:hover {
      box-shadow: 0 0 0 2px #adb5bd, 0 0 30px 0 rgba(73, 80, 87, 0.12); }
  .inspiration-post-card--invalid {
    box-shadow: 0 0 0 2px #f25270, 0 0 30px 0 rgba(73, 80, 87, 0.04); }
    .inspiration-post-card--invalid:hover {
      box-shadow: 0 0 0 2px #f25270, 0 0 30px 0 rgba(73, 80, 87, 0.12); }
  .inspiration-post-card > div:last-child {
    border-bottom-left-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem; }

.inspiration-post-card__header {
  display: flex;
  align-items: flex-start;
  margin-top: 1.25rem;
  margin-right: 1.25rem;
  margin-bottom: 0;
  margin-left: 1.25rem; }
  .inspiration-post-card__header .dropdown-menu {
    min-width: 190px; }

.inspiration-post-card__title {
  margin-bottom: 0.3125rem; }

.inspiration-post-card__date {
  display: flex;
  align-items: center;
  height: 20px;
  font-size: 0.75rem;
  text-transform: uppercase; }
  .inspiration-post-card__date svg {
    margin-right: 3px; }

.inspiration-post-card__campaign {
  font-size: 0.6375rem; }

.inspiration-post-card__content {
  margin: 1.25rem;
  font-weight: 300;
  font-size: 0.875rem;
  height: 7.5em; }

.inspiration-post-card__media {
  height: 164px; }

.inspiration-post-card__tags {
  height: 45px; }

.inspiration-post-card__tags-more {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #343a40;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 500;
  margin-left: 0.625rem; }

.inspiration-post-card__metrics {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.625rem 1.25rem; }

.inspiration-post-card__metric {
  margin-right: 0.625rem;
  font-weight: 300;
  font-size: 0.75rem; }
  .inspiration-post-card__metric span {
    font-weight: 500; }

.inspiration-post-card__inspiration-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 1.25rem;
  font-size: 1.5rem;
  border-top: 1px solid #dee2e6; }

.inspiration-post-card__footer .btn {
  width: 100%;
  border-radius: 0 0 0.3125rem 0.3125rem; }

.post-card__imported {
  width: fit-content !important;
  margin: 0 1.25rem; }

.post-card__error {
  background-color: #f25270; }

.post-card__media-wrap {
  width: 100%;
  height: 200px;
  margin: 0.625rem 0 0; }
  @media (min-width: 768px) {
    .post-card__media-wrap {
      width: 150px;
      height: 150px;
      margin: 0 0 0 1.25rem; } }

.post-card__content {
  word-break: break-word; }

.post-media {
  display: grid;
  grid-gap: 1px;
  width: 100%;
  height: 100%; }

.post-media--empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  color: #adb5bd;
  font-weight: 500;
  font-size: 1rem; }

.post-media__image {
  background-size: cover;
  background-position: 50%; }

.post-media__video {
  width: 100%;
  height: 100%; }

.post-media--1 {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr; }
  .post-media--1 .post-media__image:nth-child(1) {
    grid-column: 1;
    grid-row: 1; }

.post-media--2 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr; }
  .post-media--2 .post-media__image:nth-child(1) {
    grid-column: 1;
    grid-row: 1; }
  .post-media--2 .post-media__image:nth-child(2) {
    grid-column: 2;
    grid-row: 1; }

.post-media--3 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr; }
  .post-media--3 .post-media__image:nth-child(1) {
    grid-column: 1 / 3;
    grid-row: 1; }
  .post-media--3 .post-media__image:nth-child(2) {
    grid-column: 1;
    grid-row: 2; }
  .post-media--3 .post-media__image:nth-child(3) {
    grid-column: 2;
    grid-row: 2; }

.post-media--4 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr; }
  .post-media--4 .post-media__image:nth-child(1) {
    grid-column: 1;
    grid-row: 1 / 2; }
  .post-media--4 .post-media__image:nth-child(2) {
    grid-column: 2;
    grid-row: 1 / 2; }
  .post-media--4 .post-media__image:nth-child(3) {
    grid-column: 1;
    grid-row: 2 / 3; }
  .post-media--4 .post-media__image:nth-child(4) {
    grid-column: 2;
    grid-row: 2 / 3; }

.stepper__value {
  height: 1.25rem;
  width: 1.25rem; }

.tag {
  font-size: 100%; }
  .tag .icon {
    margin-bottom: -1px; }
  .tag__tag-icon {
    margin-right: 0.5em; }
  .tag__remove-icon {
    width: 0.75em;
    height: 0.75em;
    margin-left: 0.5em; }

.ghost {
  background-color: #dee2e6;
  border-radius: 2px;
  margin-bottom: 0.5625rem;
  min-height: 0.5625rem; }

.ghost-sm {
  min-height: 0.3125rem; }

.ghost-lg {
  min-height: 0.7875rem; }

.form-password-input__toggle-password-text {
  min-width: 2.5rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1.25rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.625rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 500; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #145c6a;
  background-color: #d4eff5;
  border-color: #c3e9f1; }
  .alert-primary hr {
    border-top-color: #aee1ec; }
  .alert-primary .alert-link {
    color: #0c373f; }

.alert-secondary {
  color: #240a61;
  background-color: #dad0f1;
  border-color: #cbbdec; }
  .alert-secondary hr {
    border-top-color: #bba9e6; }
  .alert-secondary .alert-link {
    color: #130533; }

.alert-success {
  color: #146a4f;
  background-color: #d4f5ea;
  border-color: #c3f1e2; }
  .alert-success hr {
    border-top-color: #aeecd8; }
  .alert-success .alert-link {
    color: #0c3f2f; }

.alert-info {
  color: #004d7d;
  background-color: #cceafc;
  border-color: #b8e1fb; }
  .alert-info hr {
    border-top-color: #a0d7fa; }
  .alert-info .alert-link {
    color: #002e4a; }

.alert-warning {
  color: #7d602c;
  background-color: #fcf1dd;
  border-color: #fbebcf; }
  .alert-warning hr {
    border-top-color: #f9e1b7; }
  .alert-warning .alert-link {
    color: #57431f; }

.alert-danger {
  color: #7e2b3a;
  background-color: #fcdce2;
  border-color: #fbcfd7; }
  .alert-danger hr {
    border-top-color: #f9b7c3; }
  .alert-danger .alert-link {
    color: #581e28; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

body {
  font-size: 0.875rem;
  background-color: #fff; }

.border-top {
  border-top: 1px solid #e5e5e5; }

.border-bottom {
  border-bottom: 1px solid #e5e5e5; }

.icon {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom; }

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1); }

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  height: 100vh;
  padding-top: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */ }

.sidebar .nav-link {
  font-weight: 500;
  color: #333; }

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999; }

.sidebar .nav-link.active {
  color: #27b0cc; }

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit; }

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase; }

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1); }

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25); }

input[type='date']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none; }
